import Vue from "vue";
import Router from "vue-router";
import store from './core/services/store/';
import { LOGOUT } from "@/core/services/store/auth.module";
import firebase from 'firebase/app'
import 'firebase/auth'
import * as fb from './api/firebase.js';

Vue.use(Router);

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/view/layout/Layout"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/view/pages/dashboard/CoachFeed.vue"),
        meta: {
          title: 'Dashboard',
          requiresAuth: true
        }
      },
      {
        path: "/coaches",
        name: "coaches",
        component: () => import("@/view/pages/coach/Coaches.vue"),
        meta: {
          title: 'Coaches',
          requiresAuth: true
        }
      },
      {
        path: "/coachedetail/:coachId",
        name: "coachdetail",
        component: () => import("@/view/pages/coach/CoachDetail.vue"),
        meta: {
          title: 'Coaches Detail',
          requiresAuth: true
        }
      },
      {
        path: "/personal",
        name: "personal",
        component: () => import("@/view/pages/profile/profile-comp/PersonalInformation.vue"),
        meta: {
          title: 'Update Personal Info',
          requiresAuth: true
        }
      },
      {
        path: "/password",
        name: "password",
        component: () => import("@/view/pages/profile/profile-comp/ChangePassword.vue"),
        meta: {
          title: 'Change Password',
          requiresAuth: true
        }
      },
      {
        path: "/sessiondetails/:coachlistid/:sessionId",
        name: "adminsessiondetail",
        component: () => import("@/view/pages/admin/SessionDetail.vue"),
        meta: {
          requiresEmployee: true,
          requiresAuth: true,
          title: 'Admin'
        },
      },
      {
        path: "/feeddetails/:feedId/:sessionId/:coachlistId",
        name: "feeddetail",
        component: () => import("@/view/pages/feed/FeedDetail.vue"),
        meta: {
          title: 'Dashboard',
          requiresAuth: true
        }
      },
      {
        path: "/feeddetails/:feedId",
        name: "feeddetailother",
        component: () => import("@/view/pages/feed/FeedDetailOther.vue"),
        meta: {
          title: 'Dashboard',
          requiresAuth: true
        }
      },
      {
        path: "/livestreamtest",
        name: "livestreamtest",
        component: () => import("@/view/pages/livestream/Testing.vue"),
        meta: {
          title: 'Livestream Test',
          requiresAuth: true
        }
      },
      {
        path: "/livestream",
        name: "livestream",
        component: () => import("@/view/pages/livestream/Livestream.vue"),
        meta: {
          title: 'Livestream',
          requiresAuth: true
        }
      },
      {
        path: "/livestreamingnow",
        name: "livestreamingnow",
        component: () => import("@/view/pages/livestream/LivestreamingNow.vue"),
        meta: {
          title: 'Livestream',
          requiresAuth: true
        }
      },
      {
        path: "/livestreamingtest",
        name: "livestreamingtest",
        component: () => import("@/view/pages/livestream/LivestreamTest.vue"),
        meta: {
          title: 'Livestream',
          requiresAuth: true,
          requiresEmployee: true
        }
      },
      {
        path: "/bestpractices",
        name: 'bestpractices',
        component: () => import("@/view/pages/livestream/BestPractices.vue"),
        meta: {
          title: 'Best Practices',
          requiresAuth: true
        }
      },
      {
        path: "/faq",
        name: "faq",
        component: () => import("@/view/pages/faq/faq.vue"),
        meta: {
          title: 'FAQ',
          requiresAuth: true
        }
      },
      {
        path: "/blog",
        name: "blog",
        meta: {
          requiresAdmin: true,
          requiresAuth: true,
          title: 'Admin',
        },
        component: () => import("@/view/pages/blog/blog.vue"),
      },
      {
        path: "/calendar",
        name: "calendar",
        meta: {
          requiresAuth: true,
          title: 'Calendar',
        },
        component: () => import("@/view/pages/calendar/calendar.vue"),
      },
      {
        path: "/createblog",
        name: "createblog",
        meta: {
          requiresAdmin: true,
          requiresAuth: true,
          title: 'Admin'
        },
        component: () => import("@/view/pages/blog/CreateBlog.vue")
      },
      {
        path: "/editblog/:blogId",
        name: "editblog",
        meta: {
          requiresAdmin: true,
          requiresAuth: true,
          title: 'Admin'
        },
        component: () => import("@/view/pages/blog/EditBlog.vue")
      },
      {
        path: "/admin/coaches",
        name: "admincoaches",
        meta: {
          requiresEmployee: true,
          requiresAuth: true,
          title: 'Admin'
        },
        component: () => import("@/view/pages/admin/Coaches.vue")
      },
      {
        path: "/admin/feed",
        name: "adminfeed",
        meta: {
          requiresEmployee: true,
          requiresAuth: true,
          title: 'Admin'
        },
        component: () => import("@/view/pages/admin/AdminFeed.vue")
      },
      {
        path: "/admin/coachedetail/:coachId",
        name: "admincoachdetail",
        meta: {
          requiresEmployee: true,
          requiresAuth: true,
          title: 'Admin'
        },
        component: () => import("@/view/pages/admin/CoachDetail.vue")
      },
      {
        path: "/admin/signup",
        name: "coachsignup",
        meta: {
          requiresAdmin: true,
          requiresAuth: true,
          title: 'Admin'
        },
        component: () => import("@/view/pages/admin/CoachSignup.vue")
      },
      {
        path: "/admin/users",
        name: "adminusers",
        meta: {
          requiresAdmin: true,
          requiresAuth: true,
          title: 'Admin'
        },
        component: () => import("@/view/pages/admin/Users.vue")
      },
      {
        path: "/admin/freetrial",
        name: "freetrialsignup",
        meta: {
          requiresAdmin: true,
          requiresAuth: true,
          title: 'Admin'
        },
        component: () => import("@/view/pages/admin/FreeTrialSignup.vue")
      },
      {
        path: "/admin/corporate/:corpId/group/:codegroupId",
        name: "corporatecodes",
        meta: {
          requiresAdmin: true,
          requiresAuth: true,
          title: 'Admin'
        },
        component: () => import("@/view/pages/admin/CorporateCodes.vue")
      },
      {
        path: "/admin/corporate/codegenerate/:corpId",
        name: "codegenerate",
        meta: {
          requiresAdmin: true,
          requiresAuth: true,
          title: 'Admin'
        },
        component: () => import("@/view/pages/admin/CodeGenerate.vue")
      },
      {
        path: "/admin/corporate/edit/:corpId",
        name: "corporateedit",
        meta: {
          requiresAdmin: true,
          requiresAuth: true,
          title: 'Admin'
        },
        component: () => import("@/view/pages/admin/CorporateEdit.vue")
      },
      {
        path: "/admin/corporate/:corpId",
        name: "corporatedetail",
        meta: {
          requiresAdmin: true,
          requiresAuth: true,
          title: 'Admin'
        },
        component: () => import("@/view/pages/admin/CorporateDetail.vue")
      },
      {
        path: "/admin/corporate/signup",
        name: "corporatesignup",
        meta: {
          requiresAdmin: true,
          requiresAuth: true,
          title: 'Admin'
        },
        component: () => import("@/view/pages/admin/CorporateSignup.vue")
      },
      {
        path: "/admin/corporate",
        name: "corporate",
        meta: {
          requiresAdmin: true,
          requiresAuth: true,
          title: 'Admin'
        },
        component: () => import("@/view/pages/admin/Corporate.vue")
      },
      {
        path: "/admin/notifications",
        name: "sendnotifications",
        meta: {
          requiresAdmin: true,
          requiresAuth: true,
          title: 'Admin'
        },
        component: () => import("@/view/pages/admin/Notifications.vue")
      }
    ]
  },
  {
    path: "/",
    component: () => import("@/view/pages/auth/login_pages/Login-1"),
    children: [
      {
        name: "login",
        path: "/login",
        component: () => import("@/view/pages/auth/login_pages/Login-1"),
        meta: {
          title: 'Ingomu Coaching',
          metaTags: [
            {
              name: 'description',
              content: 'Login page for Ingomu coaching.'
            },
            {
              property: 'og:description',
              content: 'Login page for Ingomu coaching.'
            }
          ]
        }
      },
      {
        name: "register",
        path: "/register",
        component: () => import("@/view/pages/auth/login_pages/Login-1"),
        meta: {
          title: 'Ingomu Coaching',
          metaTags: [
            {
              name: 'description',
              content: 'Login page for Ingomu coaching.'
            },
            {
              property: 'og:description',
              content: 'Login page for Ingomu coaching.'
            }
          ]
        }
      }
    ]
  },
  {
    path: "*",
    redirect: "/404"
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/view/pages/error/Error-6.vue"),
    meta: {
      title: 'Ingomu Coaching Error',
      metaTags: [
        {
          name: 'description',
          content: 'Error page for Ingomu coaching.'
        },
        {
          property: 'og:description',
          content: 'Error page for Ingomu coaching.'
        }
      ]
    }
  }
]

const router = new Router({
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to && to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 80 }, // avoid blocking the view when having fixed components
        behavior: 'smooth'
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
  mode: 'history'
});

router.beforeEach(async (to, from, next) => {

  const initializeAuth = new Promise(resolve => {
    // this adds a hook for the initial auth-change event
    firebase.auth().onAuthStateChanged(user => {
      resolve(user)
    })
  })

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth) {
    initializeAuth.then(user => {
      if (user && !user.isAnonymous) {
        fb.usersCollection.where("user_id", "==", user.uid).where("isCoach", "==", true).get().then((doc) => {
          if (doc.docs.length > 0) {
            const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
            const requiresEmployee = to.matched.some(record => record.meta.requiresEmployee);
            if (requiresAdmin) {
              fb.usersCollection.where("user_id", "==", user.uid).where("isCoach", "==", true).where("isAdmin", "==", true).onSnapshot((snapshot) => {
                if (snapshot.docs.length > 0) {
                  next()
                } else {
                  firebase.auth().signOut().then(() => {
                    next('/login')
                  })
                }
              }, (error) => {
                firebase.auth().signOut().then(() => {
                  next('/login')
                })
              })
            } else if (requiresEmployee) {
              fb.usersCollection.where("user_id", "==", user.uid).where("isCoach", "==", true).where("isEmployee", "==", true).onSnapshot((snapshot) => {
                if (snapshot.docs.length > 0) {
                  next()
                } else {
                  firebase.auth().signOut().then(() => {
                    next('/login')
                  })
                }
              }, (error) => {
                firebase.auth().signOut().then(() => {
                  next('/login')
                })
              })
            } else {
              next()
            }
          } else {
            fb.usersCollection.where("user_id", "==", user.uid).where("isAdmin", "==", true).get().then((doc) => {
              if (doc.docs.length > 0) {
                const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
                const requiresEmployee = to.matched.some(record => record.meta.requiresEmployee);
                if (requiresAdmin) {
                  fb.usersCollection.where("user_id", "==", user.uid).where("isAdmin", "==", true).onSnapshot((snapshot) => {
                    if (snapshot.docs.length > 0) {
                      next()
                    } else {
                      firebase.auth().signOut().then(() => {
                        next('/login')
                      })
                    }
                  }, (error) => {
                    firebase.auth().signOut().then(() => {
                      next('/login')
                    })
                  })
                } else if (requiresEmployee) {
                  fb.usersCollection.where("user_id", "==", user.uid).where("isEmployee", "==", true).onSnapshot((snapshot) => {
                    if (snapshot.docs.length > 0) {
                      next()
                    } else {
                      firebase.auth().signOut().then(() => {
                        next('/login')
                      })
                    }
                  }, (error) => {
                    firebase.auth().signOut().then(() => {
                      next('/login')
                    })
                  })
                } else {
                  next()
                }
              } else {

                fb.usersCollection.where("user_id", "==", user.uid).where("isEmployee", "==", true).get().then((doc) => {
                  if (doc.docs.length > 0) {
                    const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
                    const requiresEmployee = to.matched.some(record => record.meta.requiresEmployee);
                    if (requiresAdmin) {
                      fb.usersCollection.where("user_id", "==", user.uid).where("isAdmin", "==", true).onSnapshot((snapshot) => {
                        if (snapshot.docs.length > 0) {
                          next()
                        } else {
                          firebase.auth().signOut().then(() => {
                            next('/login')
                          })
                        }
                      }, (error) => {
                        firebase.auth().signOut().then(() => {
                          next('/login')
                        })
                      })
                    } else if (requiresEmployee) {
                      fb.usersCollection.where("user_id", "==", user.uid).where("isEmployee", "==", true).onSnapshot((snapshot) => {
                        if (snapshot.docs.length > 0) {
                          next()
                        } else {
                          firebase.auth().signOut().then(() => {
                            next('/login')
                          })
                        }
                      }, (error) => {
                        firebase.auth().signOut().then(() => {
                          next('/login')
                        })
                      })
                    } else {
                      next()
                    }
                  } else {
                    fb.usersCollection.where("user_id", "==", user.uid).where("isVendor", "==", true).get().then((doc) => {
                      if (doc.docs.length > 0) {
                          if (to.name == "coaches" || to.name == "coachdetail" || to.name == "livestreamingnow" || to.name == "password") {
                            next()
                          } else {
                            next('/coaches')
                          }
                      } else {
                        firebase.auth().signOut().then(() => {
                          next('/login')
                        })
                      }
                    })
                      .catch((error) => {
                        firebase.auth().signOut().then(() => {
                          next('/login')
                        })
                      })
                  }
                }).catch((error) => {
                  firebase.auth().signOut().then(() => {
                    next('/login')
                  })
                })

              }
            }).catch((error) => {
              firebase.auth().signOut().then(() => {
                next('/login')
              })
            })
          }
        }).catch((error) => {
          firebase.auth().signOut().then(() => {
            next('/login')
          })
        })
      } else {
        firebase.auth().signOut().then(() => {
          next('/login')
        })
      }
    })
  } else {
    next();
  }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }
  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();
  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

})

async function isAdmin() {
  var userInfo = await fb.firebase.getCurrentUser()
  let isAdmin = (userInfo.isAdmin) ? true : false
  return isAdmin
}

export default router;
